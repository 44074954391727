import React from 'react';
import pdfImage from './onepager.png'; // Import your PNG image
import './App.css';

function App() {
  return (
      <div className="App">
        <div className="container">
          <img src={pdfImage} alt="PDF Content" />
        </div>
      </div>
  );
}

export default App;