// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #24262C; /* Updated background color */
  color: #ffffff;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container {
  max-width: 100%;
  max-height: 100vh;
  overflow: auto;
  background-color: #2A2C32; /* Slightly lighter than the body for contrast */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Customizing scrollbar for webkit browsers */
.container::-webkit-scrollbar {
  width: 12px;
}

.container::-webkit-scrollbar-track {
  background: #2F3137;
}

.container::-webkit-scrollbar-thumb {
  background-color: #3A3D44;
  border-radius: 6px;
  border: 3px solid #2F3137;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,yBAAyB,EAAE,6BAA6B;EACxD,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,yBAAyB,EAAE,gDAAgD;EAC3E,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,cAAc;AAChB;;AAEA,8CAA8C;AAC9C;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  min-height: 100vh;\n  background-color: #24262C; /* Updated background color */\n  color: #ffffff;\n}\n\n.App {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n}\n\n.container {\n  max-width: 100%;\n  max-height: 100vh;\n  overflow: auto;\n  background-color: #2A2C32; /* Slightly lighter than the body for contrast */\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\nimg {\n  max-width: 100%;\n  height: auto;\n  display: block;\n}\n\n/* Customizing scrollbar for webkit browsers */\n.container::-webkit-scrollbar {\n  width: 12px;\n}\n\n.container::-webkit-scrollbar-track {\n  background: #2F3137;\n}\n\n.container::-webkit-scrollbar-thumb {\n  background-color: #3A3D44;\n  border-radius: 6px;\n  border: 3px solid #2F3137;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
